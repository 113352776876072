<template>
  <v-container fluid class="px-8">
    <v-row align="center">
      <v-col>
        <h3>Users</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-actions class="pa-5">
            <v-row >
              <v-col>
                <v-text-field
                    v-model.trim="table.search"
                    label="Search..."
                    solo
                    outlined
                    dense
                    flat
                    hide-details
                    class="font-size-15"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="table.headers"
          :items="userList"
          :items-per-page="10"
          class="table--sm show-sort-icons-always elevation-0 custom--border fill-space-first-cell table--row-70"
          :search="table.search"
          loading-text="Loading... Please wait"
          :loading="table.loading"
          hide-default-footer
          :page.sync="table.page"
          @page-count="table.pageCount = $event"
        >
          <template v-slot:item.accessGranted="{ item }">
            <v-switch
                class="ma-0 pa-0 v-switch--centered"
                dense
                hide-details
                v-model="item.accessGranted"
                inset
                color="cyan accent-4"
                @change="handleSwitchChanged(item)"
            ></v-switch>
          </template>

          <template v-slot:item.accessGrantedUntilToSort="{ item }">

            <div @click="setAccessDate(item)" class="cursor-pointer d-flex align-center justify-center">
              <v-icon class="flex-grow-1" color="cyan accent-4" small left>mdi-calendar</v-icon>

              <div style="width: 200px">
                <span v-if="!item.accessGrantedUntilFormatted">
                  No access
                </span>
                <span v-else>
                  access until {{item.accessGrantedUntilFormatted}}
                </span>
              </div>
            </div>
          </template>

          <template v-slot:item.dateToSort="{ item }">
            <span>{{item.dateFormatted}}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="mt-3 mb-0" align="center" v-show="table.pageCount > 1">
      <v-col cols="12">
        <v-pagination
            v-model="table.page"
            :length="table.pageCount"
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog
        v-model="chooseDateModal.show"
        width="362"
    >
      <v-card outlined >
        <v-date-picker :min="moment().format(DATE_FORMATS.yearMonthDayWithDash)" color="cyan accent-4" width="360" v-model="chooseDateModal.date"></v-date-picker>

        <div class="px-4">
          <v-row class="mb-3">
            <v-col>
              <v-btn
                  class="custom--btn" outlined depressed color="cyan accent-4" @click="chooseDateModal.show = false" block height="40"
              >
                Cancel
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                  class="custom--btn"  dark depressed color="cyan accent-4" block type="submit" height="40" @click="handleDateChange"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-card>

    </v-dialog>

  </v-container>
</template>

<script>

import { mapGetters, mapState, mapMutations } from 'vuex';
import {showNotification} from "../js/helpers";
import usersApi from '../api/users';
import moment from 'moment';
import {DATE_FORMATS} from "../helpers/contants";

export default {
  name: 'Users',

  components: {
  },

  watch: {

  },

  computed: {
    ...mapState('users', ['userList'])
  },

  methods: {
    ...mapMutations(['setLoading']),
    setAccessDate(user) {
      const { accessGrantedUntil } = user;

      let dateForCalendar;
      if (accessGrantedUntil) {
        dateForCalendar = moment(accessGrantedUntil).format(DATE_FORMATS.yearMonthDayWithDash);
      }

      this.chooseDateModal.userToUpdate = user;
      this.chooseDateModal.date = dateForCalendar;

      this.$nextTick(function() {
        this.chooseDateModal.show = true;
      });
    },
    async onUserDataChanged(userId, accessGrantedUntil, accessGranted) {
      this.table.loading = true;
      const res = await usersApi.updateUser({ _id: userId, accessGrantedUntil, accessGranted });

      this.$nextTick(function() {
        if (res.status) {
          showNotification({ title: 'Updated!' });
        } else {
          this.$store.commit('users/updateUser', { _id: userId, accessGranted: false, accessGrantedUntil: '' });
        }

        this.table.loading = false;
      });
    },
    async handleDateChange() {
      const { userToUpdate: { _id }, date } = this.chooseDateModal;
      if (!date) { return showNotification({ type: 'error', title: 'Choose Date' }) }

      this.chooseDateModal.show = false;

      const accessGrantedUntil = moment(date, DATE_FORMATS.yearMonthDayWithDash).format();

      this.onUserDataChanged(_id, accessGrantedUntil, true);
    },
    handleSwitchChanged(item) {
      let { accessGrantedUntil, _id, accessGranted = false } = item;

      if (!accessGrantedUntil) {
        accessGrantedUntil = moment().add(1, 'month').format();
      }

      this.onUserDataChanged(_id, accessGrantedUntil, accessGranted);
    }
  },

  beforeCreate() {
    this.moment = moment;
    this.DATE_FORMATS = DATE_FORMATS;
  },

  async mounted() {
    this.setLoading(true);

    const response = await usersApi.getUsers();
    if (!response) { return this.$router.push('/rotator') }

    this.$nextTick(function() {
      this.setLoading(false);
    });
  },

  data: () => ({
    chooseDateModal: { show: false, date: null, userToUpdate: null },
    table: {
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'Date', value: 'dateToSort', align: 'center', width: 270 },
        { text: 'Access', value: 'accessGrantedUntilToSort', align: 'center', width: 270 },
        { text: 'Enabled', value: 'accessGranted', align: 'center', width: 270 },
      ],
      search: '',
      loading: false,
      page: 1,
      pageCount: 0
    }
  }),
}
</script>

<style lang="scss">
</style>
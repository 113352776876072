import store from '../store/index';
import {baseApiCall} from "./base";

export default {
  async getUsers() {
    if (store.state.users.loaded) { return store.state.users.userList }

    return baseApiCall({
      method: 'GET', url: `/access`,
      onSuccess: (res) => store.commit('users/setUsers', res.users)
    });
  },
  updateUser: (data) => {
    return baseApiCall({
      method: 'POST', url: `/access`, data,
      onSuccess: () => store.commit('users/updateUser', data)
    });
  },
}